import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';

import { runWithAdal } from 'react-adal';
import { authContext } from './adal-config';

runWithAdal(authContext, () => {

    ReactDOM.render(<App />, document.getElementById('root'));

})
