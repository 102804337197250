import React from 'react'
import {Col, Row, Card, CardBody, CardText} from "reactstrap";

const IncomingItemDetails = (props) => {
    const {
        naslovnik,
        naslovnikOddelek,
        casVnosa,
        datumPrispetja,
        datumVnosa,
        dostava,
        posiljatelj,
        stanje,
        stevilkaZadeve,
        vrstaPosiljke,
        zadeva
    } = props;
    return (
        <div>
            <Card>
                <CardBody>
                    <Row>
                        <Col lg='6'>
                            <CardText> {datumVnosa} </CardText>
                        </Col>
                        <Col lg='6'>
                            <CardText>{casVnosa} </CardText>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <CardText> {naslovnik} </CardText>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <CardText> {naslovnikOddelek} </CardText>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <CardText> {posiljatelj} </CardText>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <CardText> {stanje} </CardText>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <CardText> {vrstaPosiljke} </CardText>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <CardText> {vrstaPosiljke} </CardText>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <CardText> {zadeva} </CardText>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <CardText> {dostava} </CardText>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <CardText> {datumPrispetja} </CardText>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <CardText> {stevilkaZadeve} </CardText>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
};

export default IncomingItemDetails