import React, {Component} from 'react'
import {Row, Col, Input} from "reactstrap";
import IncomingMailDataItem from "./IncomingMailDataItem";

import IncomingMailDashboardHeader from "./IncomingMailDashboardHeader"

import '../main.css'
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getToken} from "../adal-config";

//API URL
import {basicUrl, seznamVhodnePoste} from '../apiUrl'

export class IncomingMailDashboard extends Component {
    state = {
        vhodnaPosta: [],
    };

    componentDidMount()     {

        const token = getToken();
        //Oddelek input field data API
        fetch(basicUrl+seznamVhodnePoste, {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((posta) => {
                this.setState({vhodnaPosta: posta}, () => {
                    console.log(this.state.vhodnaPosta);
                })
            }).catch((error) => {
            console.log(error);
        });

    }

    render() {

        let novaPostaButton;
        //Preverimo če je uporabnik Urednik in prikažemo gumb za dodajanje pošte
        if(this.props.roleInfo === 2){
            novaPostaButton = (
                <Row>
                    <Col className='text-right pb-2'>
                        <Link to='/nova' className='pr-1 text-dark text-size-19'> <FontAwesomeIcon
                            icon="plus-square" className="mr-1"/>
                            <span>Nova pošta</span></Link>
                    </Col>
                </Row>
            )
        }else{
                novaPostaButton = null;
            }


        return (
            <div>
                <header>
                    <Row>
                        <Col className='pt-5'>
                            <h2><FontAwesomeIcon icon="sign-in-alt" className='mr-3 ml-3'/>SEZNAM VHODNE POŠTE</h2>
                            <hr/>
                        </Col>
                    </Row>
                </header>
                <main>
                    <Row>
                        <Col className='mb-2' lg={{size: 6, offset: 3}}>
                            <Input type='text' className='incoming-mail-search' placeholder='Hitro iskanje...'/>
                            <FontAwesomeIcon className='incoming-mail-search-icon' icon="search"/>
                        </Col>
                    </Row>
                    {novaPostaButton}

                    <IncomingMailDashboardHeader/>
                    {
                        this.state.vhodnaPosta.map((value, index) => {
                            return <IncomingMailDataItem
                                key={value.id + value.sifra}
                                id={value.id}
                                ikona={value.ikona}
                                sifra={value.sifra}
                                status={value.status}
                                datum={value.datum}
                                posiljatelj={value.posiljatelj}
                                naslovnik={value.naslovnik}
                                oddelek={value.oddelek}
                            />
                        })
                    }
                </main>
            </div>

            // dashboard item
        )
    }
}

export default IncomingMailDashboard;