import React from 'react'
import {Col, Row, Card, CardBody, CardText} from "reactstrap";


const IncomingItemRevision = (props) => {
    const {datum, opomba, stanje, uporabnik, ura} = props;
    return (
        <div>
            <Card>
                <CardBody>
                    <Row>
                        <Col lg='6'>
                            <CardText> {datum} </CardText>
                        </Col>
                        <Col lg='6'>
                            <CardText>{opomba} </CardText>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <CardText> {stanje} </CardText>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <CardText> {uporabnik} </CardText>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg='12'>
                            <CardText> {ura} </CardText>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
};

export default IncomingItemRevision