import React, {Component} from 'react'
import {Row, Col} from 'reactstrap'

import NewUser from './NewUser';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class AddNewUser extends Component {

    render() {
        return (
            <div>
                <header>
                    <Row>
                        <Col className='pt-5'>
                            <h2><FontAwesomeIcon icon="user-plus" className='mr-3 ml-3'/>VPIS NOVEGA UPORABNIKA</h2>
                            <hr/>
                        </Col>
                    </Row>
                </header>
                <main>
                    <NewUser/>
                </main>
            </div>
        )
    }
}

export default AddNewUser;
