import React,{Component} from 'react'
import {Row, Col} from 'reactstrap'

import NewForm from './NewForm';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class NewMail extends Component {

    render() {
        return (
            <div>
                <header>
                    <header>
                        <Row>
                            <Col className='pt-5'>
                                <h2><FontAwesomeIcon icon="envelope-open-text" className='mr-3 ml-3'/>VPIS NOVE POŠTE</h2>
                                <hr/>
                            </Col>
                        </Row>
                    </header>
                </header>
                <main>
                    <NewForm/>
                </main>
            </div>
        )
    }
}

export default NewMail;
