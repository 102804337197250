import React, { Component } from 'react'

export class Outgoing extends Component {
    render() {
        return (
            <div>
                Izhodna pošta
            </div>
        )
    }
}

export default Outgoing
