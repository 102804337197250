import React from 'react'
import {Row, Col, Card} from "reactstrap";

const IncomingMailDashboardHeader = () => {
    return (
        <Card className='incoming-dashbord-header'>
            <Row>
                <Col lg={1} className='text-center incoming-dashbord-header-border'>
                    TIP
                </Col>
                <Col lg={1} className='incoming-dashbord-header-border'>
                    ŠIFRA
                </Col>
                <Col lg={2} className='incoming-dashbord-header-border'>
                    STATUS
                </Col>
                <Col lg={1} className='incoming-dashbord-header-border'>
                    DATUM
                </Col>
                <Col lg={2} className='incoming-dashbord-header-border'>
                    POŠILJATELJ
                </Col>
                <Col lg={2} className='incoming-dashbord-header-border'>
                    NASLOVNIK
                </Col>
                <Col lg={2} className='incoming-dashbord-header-border'>
                    ODDELEK
                </Col>
                <Col lg={1} className='text-center'>
                    VEČ
                </Col>
            </Row>
        </Card>
    )
}

export default IncomingMailDashboardHeader;