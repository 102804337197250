import React from 'react'
import {Route, Redirect} from 'react-router-dom'

const ProtectedRoute = ({component: Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={props => {
                    return <Redirect to={{pathname:'/'}}/>
            }}

        />

    )
}

export default ProtectedRoute;