import React from 'react'
import {Col, Row, Nav, NavItem, NavLink, TabPane, TabContent} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classnames from 'classnames';
import {getToken} from "../adal-config";
import IncomingItemDetails from "./IncomingItemDetails";
import IncomingItemRevision from "./incomingItemRevision";

//API URL
import {basicUrl, podrobnostiPosta, revizijskaSledP} from "../apiUrl";


class IncomingItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            activeTab: '1',
            postaData: [],
            revizijskaSledData: [],
            postaExp: '',
            revizijaExp: ''
        }
    }

    componentDidMount() {


        //TODO: v primeru da je URL/ in pošta ne obstaja, je potrebno zaščiti

        const token = getToken();
        //Oddelek input field data API
        fetch(basicUrl + podrobnostiPosta + this.state.id, {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((postaItem) => {
                if (postaItem === -3 || postaItem === -1 || postaItem === 0) {
                    this.setState({postaExp: true})
                } else {
                    this.setState({postaData: postaItem, postaExp: false});
                }


            }).catch((error) => {
            console.log("Error" + error);
        });

        //TODO: KLIC API REVIZIJA
        fetch(basicUrl + revizijskaSledP + this.state.id, {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((sledItem) => {
                if (sledItem === -3 || sledItem === -1 || sledItem === 0) {
                    this.setState({revizijaExp: true})
                } else {
                    this.setState({revizijskaSledData: sledItem, revizijaExp: false})
                }

            }).catch((error) => {
            console.log(error);
        });

    }


    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    };

    render() {

        let izpisPodrobnostiPoste;
        let izpisRevizijaPosta;
        if (this.state.postaExp === false && this.state.revizijaExp === false) {
            izpisPodrobnostiPoste = (this.state.postaData.map((item, index) => {
                        return (
                            <IncomingItemDetails
                                key={'posta' + item.id + index}
                                naslovnik={item.naslovnik}
                                naslovnikOddelek={item.naslovnikOddelek}
                                casVnosa={item.casVnosa}
                                datumPrispetja={item.datumPrispetja}
                                datumVnosa={item.datumVnosa}
                                dostava={item.dostava}
                                posiljatelj={item.posiljatelj}
                                stanje={item.stanje}
                                stevilkaZadeve={item.stevilkaZadeve}
                                vrstaPosiljke={item.vrstaPosiljke}
                                zadeva={item.zadeva}
                            />
                        )
                    }
                )
            );

            izpisRevizijaPosta = (this.state.revizijskaSledData.map((item, index) => {
                        return (
                            <IncomingItemRevision
                                key={'revizija' + item.id + index}
                                datum={item.datum}
                                opomba={item.opomba}
                                stanje={item.stanje}
                                uporabnik={item.uporabnik}
                                ura={item.ura}
                            />
                        )
                    }
                )
            );
        } else{
            izpisPodrobnostiPoste = (<div>Podatki niso pravilni</div>);
            izpisRevizijaPosta = (<div>Revizijska sled ni pravilna</div>)
        }
            return (
                <div>
                    <header>
                        <Row>
                            <Col className='pt-5'>
                                <h2><FontAwesomeIcon icon="edit" className='mr-3 ml-3'/>ZADEVA:</h2>
                                <hr/>
                            </Col>
                        </Row>
                    </header>
                    <main>
                        <Nav tabs>

                            <NavItem className='col-lg-6'>
                                <NavLink
                                    className={classnames({active: this.state.activeTab === '1'}, 'incoming-nav-item-first')}
                                    onClick={() => this.toggleTab('1')}
                                >
                                    INFORMACIJE
                                </NavLink>
                            </NavItem>
                            <NavItem className='col-lg-6'>
                                <NavLink
                                    className={classnames({active: this.state.activeTab === '2'}, 'incoming-nav-item-second')}
                                    onClick={() => this.toggleTab('2')}
                                >
                                    REVIZIJSKA SLED
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        {izpisPodrobnostiPoste}
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col lg="12">
                                        {izpisRevizijaPosta}
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </main>
                </div>
            );
    }
}

export default IncomingItem;