import React, { Component } from 'react'

export class Dashboard extends Component {
    render() {
        return (
            <div>
                Dashboard
            </div>
        )
    }
}

export default Dashboard
