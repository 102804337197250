import React from 'react'
import {Link} from 'react-router-dom'
import {Nav, NavItem, NavLink,} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import mainLogo from '../images/logo-um.jpg'


const Navigation = (props) => {

    function handlerLogout() {
        props.userLogout();
    }

    let navigationRole;
    switch (props.userRole) {
        //IT skrbnik
        case 1 :
            navigationRole = (
                <React.Fragment>
                    <NavItem>
                        <FontAwesomeIcon icon="cog" className='site-navigation-icons'/>
                        Administracija
                    </NavItem>
                </React.Fragment>
            );
            break;
        //Urednik
        case 2:
            navigationRole = (
                <React.Fragment>
                    <NavItem>
                        <FontAwesomeIcon icon="tachometer-alt" className='site-navigation-icons'/>
                        <Link to='/'>Nadzorna plošča</Link>
                    </NavItem>
                    <NavItem>
                        <FontAwesomeIcon icon="sign-in-alt" className='site-navigation-icons'/>
                        <Link to='/vhodna'>Vhodna pošta</Link>
                    </NavItem>
                    <NavItem>
                        <FontAwesomeIcon icon="sign-out-alt" className='site-navigation-icons'/>
                        <Link to='/izhodna'>Izhodna pošta</Link>
                    </NavItem>
                    <NavItem>
                        <FontAwesomeIcon icon="user-edit" className='site-navigation-icons'/>
                        <Link to='/dodaj-uporabnika'>Uporabniki</Link>
                    </NavItem>
                </React.Fragment>
            );
            break;
        //Uporabnik
        case 3:
            navigationRole = (
                <React.Fragment>
                    <NavItem>
                        <FontAwesomeIcon icon="igloo" className='site-navigation-icons'/>
                        <Link to='/'>Nadzorna plošča</Link>
                    </NavItem>
                    <NavItem>
                        <FontAwesomeIcon icon="sign-in-alt" className='site-navigation-icons'/>
                        <Link to='/vhodna'>Vhodna pošta</Link>
                    </NavItem>
                    <NavItem>
                        <FontAwesomeIcon icon="sign-out-alt" className='site-navigation-icons'/>
                        <Link to='/izhodna'>Izhodna pošta</Link>
                    </NavItem>
                </React.Fragment>
            );
            break;
        default:
            break;
    }

    return (
        <Nav vertical className="sidebar-sticky site-navigation-height">
            <div className='site-navigation-growth'>
                <NavItem className='site-navigation-logo'>
                    <NavLink>
                        <img src={mainLogo} alt='logo'/>
                    </NavLink>
                </NavItem>
                {navigationRole}
            </div>
            <div>
                <NavItem className='site-navigation-lastNavItem'>
                    <NavLink onClick={handlerLogout} className='text-center'>
                        <FontAwesomeIcon icon="user-circle" className='site-navigation-icons'/>
                        Odjava
                    </NavLink>
                </NavItem>
            </div>
        </Nav>
    )
};

export default Navigation
