import React, {Component} from "react";
import {Formik, Field, Form} from "formik";
import * as Yup from 'yup';
import {Row, Col, Button, FormGroup, Label,} from 'reactstrap'
import {getToken} from "../adal-config";

//API URL IMPORT
import {basicUrl, dostava, oddelek, stanje, uporabnikOddelek, vrstaPosiljke, vnosPoste} from '../apiUrl'

class NewForm extends Component {
    state = {
        naslovnikiField: [],
        oddelekField: [],
        vrstaField: [],
        dostavaField: [],
        stanjeField: []
    };

    componentDidMount() {
        const token = getToken();

        //Oddelek input field data API
        fetch(basicUrl + oddelek, {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((oddelek) => {
                this.setState({oddelekField: oddelek});
                console.log(this.state.oddelekField);

            }).catch((error) => {
            console.log(error);
        });

        //Vrsta posiljke input field data API
        fetch(basicUrl + vrstaPosiljke, {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((vrstaposilke) => {
                this.setState({vrstaField: vrstaposilke});
                console.log(this.state.vrstaField)

            }).catch((error) => {
            console.log(error);
        });

        //dostava posiljke input field data API
        fetch(basicUrl + dostava, {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((dostava) => {
                this.setState({dostavaField: dostava});
                console.log(this.state.dostavaField)

            }).catch((error) => {
            console.log(error);
        });

        //Stanje posiljke input field data API
        fetch(basicUrl + stanje, {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((stanje) => {
                this.setState({stanjeField: stanje});
                console.log(this.state.stanjeField)
            }).catch((error) => {
            console.log(error);
        });
    }

    render() {
        return (
            <Formik
                initialStatus={{
                    message: '',
                }}
                initialValues={{
                    zadeva: '',
                    posiljatelj: '',
                    datumPrispetja: '',
                    naslovnik: '',
                    oddelekNaslovnika: '',
                    stevilkaZadeve: '',
                    vrstaPosiljke: '',
                    dostava: '',
                    stanje: '',
                }}
                onSubmit={(values, {setSubmitting, resetForm, setStatus}) => {
                    setTimeout(() => {
                        const token = getToken();
                        setSubmitting(false);

                        const jsonData = {
                            zadeva: values.zadeva,
                            stevilkaZadeve: Number(values.stevilkaZadeve),
                            datumPrispetja: values.datumPrispetja,
                            posiljatelj: values.posiljatelj,
                            naslovnikID: Number(values.naslovnik),
                            vrstaPosiljkeID: Number(values.vrstaPosiljke),
                            dostavaID: Number(values.dostava),
                        };

                        console.log(jsonData);

                        let statusResponseCode;
                        fetch(basicUrl + vnosPoste, {
                            method: "POST",
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token

                            },
                            body: JSON.stringify(jsonData),
                        }).then((response) => {
                            statusResponseCode = response.status;
                            return response.json();
                        }).then(responseValue => {
                            if (statusResponseCode === 200 && responseValue === 1) {
                                resetForm();
                                setStatus({message: 'Uspešno ste vnesli podatke v formo'})

                            } else {
                                setStatus({message: "Pri vnosu pošte je prišlo do napake"});
                            }

                        }).catch((error) => {
                            console.log(error);
                        });
                    }, 2000)
                }}
                validationSchema={Yup.object().shape({
                    zadeva: Yup.string().required("Zadeva je obvezno polje").min(3, "Vnesti morate vsaj 3 znake"),
                    posiljatelj: Yup.string().required("Posiljatelj je obvezno polje").min(3, "Vnesti morate vsaj 3 znake"),
                    datumPrispetja: Yup.date().required("Datum je obvezno polje"),
                    naslovnik: Yup.number().required("Naslovnik je obvezno polje"),
                    oddelekNaslovnika: Yup.number().required("Oddelek je obvezno polje"),
                    stevilkaZadeve: Yup.number().required("Stevilka zadeve je obvezno polje"),
                    vrstaPosiljke: Yup.number().required("Vrsta pošiljke je obvezno polje").moreThan(-1, "Vrsta pošiljke je obvezno polje"),
                    dostava: Yup.number().required("Dostava je obvezno polje").moreThan(-1, 'Dostava je obvezno polje'),
                    stanje: Yup.number().required("Stanje je obvezno polje").moreThan(-1, 'Stanje je obvezno polje')
                })
                }
            >
                {({errors, touched, values, handleChange, isSubmitting, status}) => (
                    <Form>
                        <Row>
                            <Col lg='12'>
                                <FormGroup row>
                                    <Label lg='2' for="zadeva">Zadeva</Label>
                                    <Col lg='10'>
                                        <Field
                                            className="form-control"
                                            type="text"
                                            name="zadeva"
                                        />
                                        {touched.zadeva && errors.zadeva && <div>{errors.zadeva}</div>}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label lg='2' for="posiljatelj">Pošiljatelj</Label>
                                    <Col lg='10'>
                                        <Field
                                            className="form-control"
                                            type="text"
                                            name="posiljatelj"
                                        />
                                        {errors.posiljatelj && touched.posiljatelj && <div>{errors.posiljatelj}</div>}
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label lg='2' for="datumPrispetja">Datum prispetja</Label>
                                    <Col lg='10'>
                                        <Field
                                            className="form-control"
                                            type="date"
                                            name="datumPrispetja"
                                        />
                                        {errors.datumPrispetja && touched.datumPrispetja &&
                                        <div>{errors.datumPrispetja}</div>}
                                    </Col>
                                </FormGroup>
                                <hr/>
                                <FormGroup row>
                                    <Label lg='2' for="naslovnik">Naslovnik</Label>
                                    <Col lg='10'>
                                        <Field
                                            className="form-control"
                                            component="select"
                                            name="naslovnik"
                                        >
                                            <option>Izberite vrednost...</option>
                                            {this.state.naslovnikiField.map((i, key) => <option key={"kljuc" + key}
                                                                                                value={i.id}>{i.uporabnik}</option>)}
                                        </Field>
                                        {errors.naslovnik && touched.naslovnik && <div>{errors.naslovnik}</div>}
                                    </Col>
                                </FormGroup>

                                <FormGroup row>
                                    <Label lg='2' for="oddelekNaslovnika">Oddelek naslovnika</Label>
                                    <Col lg='10'>
                                        <Field
                                            component="select"
                                            name="oddelekNaslovnika"
                                            render={({field, form}) => (
                                                <select
                                                    className="form-control"
                                                    {...field}
                                                    onChange={e => {
                                                        const izbranElement = e.target.value;
                                                        handleChange(e);

                                                        const token = getToken();
                                                        //Naslovniki input field data API
                                                        fetch(basicUrl + uporabnikOddelek + izbranElement, {
                                                            method: "GET",
                                                            headers: {
                                                                'Access-Control-Allow-Origin': '*',
                                                                'Accept': 'application/json',
                                                                'Authorization': 'Bearer ' + token
                                                            },
                                                        })
                                                            .then((response) => {
                                                                return response.json();
                                                            })
                                                            .then((naslovniki) => {
                                                                this.setState({naslovnikiField: naslovniki}, () => form.setFieldValue('naslovnik', this.state.naslovnikiField[0].id));
                                                            }).catch((error) => {
                                                            console.log(error);
                                                        });

                                                    }}
                                                >
                                                    <option>Izberite vrednost...</option>
                                                    {this.state.oddelekField.map((i, key) => <option key={"kljuc" + key}
                                                                                                     value={i.id}>{i.naziv}</option>)}
                                                </select>
                                            )}
                                        />
                                        {errors.oddelekNaslovnika && touched.oddelekNaslovnika &&
                                        <div>{errors.oddelekNaslovnika}</div>}
                                    </Col>
                                </FormGroup>

                                <FormGroup row>
                                    <Label lg='2' for="stevilkaZadeve">Številka zadeve</Label>
                                    <Col lg='10'>
                                        <Field
                                            className="form-control"
                                            type="text"
                                            name="stevilkaZadeve"
                                        />
                                        {errors.stevilkaZadeve && touched.stevilkaZadeve &&
                                        <div>{errors.stevilkaZadeve}</div>}
                                    </Col>


                                </FormGroup>
                                <hr/>
                                <FormGroup row>
                                    <Label lg='2' for="vrstaPosiljke">Vrsta pošiljke</Label>
                                    <Col lg='10'>
                                        <Field
                                            className="form-control"
                                            component="select"
                                            name="vrstaPosiljke"
                                        >
                                            <option value={-1}>Izberite vrednost...</option>
                                            {this.state.vrstaField.map((i, key) => <option key={"kljuc" + key}
                                                                                           value={i.id}>{i.vrstaPosiljke}</option>)}
                                        </Field>
                                        {errors.vrstaPosiljke && touched.vrstaPosiljke &&
                                        <div>{errors.vrstaPosiljke}</div>}
                                    </Col>
                                </FormGroup>

                                <FormGroup row>
                                    <Label lg='2' for="dostava">Dostava</Label>
                                    <Col lg='10'>
                                        <Field
                                            className="form-control"
                                            component="select"
                                            name="dostava"
                                        >
                                            <option value={-1}>Izberite vrednost...</option>
                                            {this.state.dostavaField.map((i, key) => <option key={"kljuc" + key}
                                                                                             value={i.id}>{i.naziv}</option>)}
                                        </Field>
                                        {errors.dostava && touched.dostava && <div>{errors.dostava}</div>}
                                    </Col>
                                </FormGroup>

                                <hr/>

                                <FormGroup row>
                                    <Label lg='2' for="stanje">Stanje</Label>
                                    <Col lg='10'>
                                        <Field
                                            className="form-control"
                                            component="select"
                                            name="stanje"
                                        >
                                            <option value={-1}>Izberite vrednost...</option>
                                            {this.state.stanjeField.map((i, key) => <option key={"kljuc" + key}
                                                                                            value={i.id}>{i.naziv}</option>)}
                                        </Field>
                                        {errors.stanje && touched.stanje && <div>{errors.stanje}</div>}
                                    </Col>
                                </FormGroup>
                                {status.message && <div>{status.message}</div>}
                                <hr/>
                                <Button outline color="success" size="lg" type="submit" disabled={isSubmitting}>
                                    VNOS POŠTE
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        )
    }
}

export default NewForm;