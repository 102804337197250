//API URL default
const basicUrl = 'https://umpostawstest.u-m.si/api/';

//GET --> PRIJAVA
const  prijava = 'prijavljenuporabnik/vlogaid';

//GET SIFRANTI (Za dropdown polja pri vnosu pošte)
const dostava = 'sifrant/dostava';
const oddelek = 'sifrant/oddelek';
const posiljatelj = 'sifrant/posiljatelj';
const stanje = 'sifrant/stanje';
const uporabnikOddelek = 'sifrant/uporabnik/'; //+id (id oddelka)
const vrstaPosiljke = 'sifrant/vrstaposiljke';

//GET SIFRANTI PRI VNOSU UPORABNIKA
const vlogaUporabnika = 'sifrant/vloga';

//GET REVIZIJSKA SLED
const revizijskaSledP = 'revizijskasled/'; //+id (id poste)

//GET SEZNAM VHODNE POSTE
const seznamVhodnePoste = 'vhodnaposta';

//GET podrobnosti za posamezno vhodno pošto
const podrobnostiPosta = 'vhodnaposta/'; //+id (id poste)

//POST VNOS POŠTE
const vnosPoste = 'vhodnaposta/vnos';

//POST SPREMEMBA POŠTE (ko spremenimo podatke o pošti razen stanja)
const spremembaPoste = 'vhodnaposta/sprememba';

//POST VPIS REVIIJSKE SLEDI (samo ob spremembi stanja vhodne pošte)
const revizijskaSled = 'revizijskasled';

//POST UPORABNIK VNOS
const vnosUporabnika = 'uporabnik/vnos';



export {basicUrl, prijava, dostava, oddelek, posiljatelj, stanje, uporabnikOddelek, vrstaPosiljke, vnosPoste, seznamVhodnePoste, podrobnostiPosta, revizijskaSledP, vlogaUporabnika, vnosUporabnika, spremembaPoste, revizijskaSled};
