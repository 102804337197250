import React, {Component} from "react";
import {Formik, Field, Form} from "formik";
import * as Yup from 'yup';
import {Row, Col, Button, FormGroup, Label,} from 'reactstrap'
import {getToken} from "../adal-config";
import {basicUrl, vlogaUporabnika, vnosUporabnika} from "../apiUrl";


class NewUser extends Component {

    state = {
        vlogaField: [],
    };

    componentDidMount() {
        //API CALL
        //Oddelek input field data API
        const token = getToken();
        fetch(basicUrl + vlogaUporabnika, {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((vloga) => {
                this.setState({vlogaField: vloga});
                console.log(this.state.vlogaField);

            }).catch((error) => {
            console.log(error);
        });
    }

    render() {
        return (

                <Formik
                    initialStatus={{
                        message: '',
                    }}
                    initialValues={{
                        enaslovUporabnika: '',
                        vlogaUporabnika: '',
                    }}
                    onSubmit={(values, {setSubmitting, resetForm, setStatus}) => {
                        setTimeout(() => {
                            const token = getToken();
                            setSubmitting(false);

                            const jsonData = {
                                upn: values.enaslovUporabnika,
                                vlogaID: Number(values.vlogaUporabnika),
                            };

                            let statusResponseCode;
                            fetch(basicUrl + vnosUporabnika, {
                                method: "POST",
                                headers: {
                                    'Access-Control-Allow-Origin': '*',
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + token

                                },
                                body: JSON.stringify(jsonData),
                            }).then((response) => {
                                statusResponseCode = response.status;
                                return response.json();
                            }).then(responseValue => {
                                console.log(responseValue);
                                if (statusResponseCode === 200 && responseValue === 1) {
                                    resetForm();
                                    setStatus({message: 'Uspešno ste vnesli novega uporabnika: ' + jsonData.upn})

                                } else if (statusResponseCode === 200 && responseValue === 0) {
                                    setStatus({message: "Vnešeni so bili napačni podatki"});
                                } else if (statusResponseCode === 200 && responseValue === -2) {
                                    setStatus({message: "Ta uporabnik že obstaja v programu pošta"});
                                } else if (statusResponseCode === 200 && responseValue === -3) {
                                    setStatus({message: "Elektronski naslov ne obstaja"});
                                } else {
                                    setStatus({message: "Pri vnosu uporabnika je prišlo do napake"});
                                }

                            }).catch((error) => {
                                console.log(error);
                            });
                        }, 2000)
                    }}
                    validationSchema={Yup.object().shape({
                        enaslovUporabnika: Yup.string().required('Elektronski naslov je obvezno polje').email("Vnesti je potrebno pravilen elektronski naslov"),
                        vlogaUporabnika: Yup.number().required("Vloga uporabnika je obvezno polje").moreThan(-1, "vloga uporabnika je obvezno polje")
                    })
                    }
                >
                    {({errors, touched, values, handleChange, isSubmitting, status}) => (
                        <Form>
                            <Row>
                                <Col lg='12'>
                                    <FormGroup row>
                                        <Label lg='2' for="enaslovUporabnika">Elektronski naslov</Label>
                                        <Col lg='10'>
                                            <Field
                                                className="form-control"
                                                type="email"
                                                name="enaslovUporabnika"
                                            />
                                            {touched.enaslovUporabnika && errors.enaslovUporabnika &&
                                            <div>{errors.enaslovUporabnika}</div>}
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label lg='2' for="vlogaUporabnika">Vloga Uporabnika</Label>
                                        <Col lg='10'>
                                            <Field
                                                className="form-control"
                                                component="select"
                                                name="vlogaUporabnika"
                                            >
                                                <option>Izberite vrednost...</option>
                                                {this.state.vlogaField.map((i, key) => <option key={"kljuc" + key}
                                                                                               value={i.id}>{i.vloga}</option>)}
                                            </Field>
                                            {errors.vlogaUporabnika && touched.vlogaUporabnika &&
                                            <div>{errors.vlogaUporabnika}</div>}
                                        </Col>
                                    </FormGroup>

                                    {status.message && <div>{status.message}</div>}
                                    <hr/>
                                    <Button outline color="success" size="lg" type="submit" disabled={isSubmitting}>
                                        VNOS UPORABNIKA
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
        )
    }
}

export default NewUser;