import { AuthenticationContext } from 'react-adal';

const addalDev = 'http://localhost:3000';
const addalProduction = 'https://evidencapostetest.u-m.si';

const adalConfig = {
    tenant: 'studentum.onmicrosoft.com',
    clientId: 'e6121b80-9e72-491e-9a9e-2d56f83ef8c7',
    endpoints: {
        api: 'e6121b80-9e72-491e-9a9e-2d56f83ef8c7'
    },
    postLogoutRedirectUri: window.location.origin,
    redirectUri: 'https://evidencapostetest.u-m.si',
    cacheLocation: 'sessionStorage'
};
export const authContext = new AuthenticationContext(adalConfig);
export const getToken = () => {
    return authContext.getCachedToken(authContext.config.clientId);
};

export const logoutUser = () => {
    return authContext.logOut();
};
