import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom'
import IncomingMailDashboard from './IncomingMailDashboard';
import IncomingItem from "./IncomingItem";
import Error from "./Error";


export class Incoming extends Component {
    constructor(props){
        super(props);
        this.state={
            role: this.props.roleInfo,
        }
    }
    componentDidMount(){
    }


    render() {
        return (
            <div>
                <Switch>
                    <Route exact path='/vhodna'
                           render={()=> (<IncomingMailDashboard roleInfo={this.state.role}/>)}/>
                    <Route path='/vhodna/:id' component={IncomingItem}/>
                    <Route component={Error}/>
                </Switch>
            </div>


        )
    }
}

export default Incoming
