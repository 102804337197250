import React from 'react'
import {Row, Col, Card} from "reactstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'


const IncomingMailDataItem = (props) => {
    const {sifra, ikona, status, posiljatelj, naslovnik, datum, id, oddelek} = props;
    return (
        <main>
            <Card className='incomingMail-item'>
                <Row className='justify-content-lg-center'>
                    <Col lg={1} className='text-center'>
                        <FontAwesomeIcon icon={ikona} size='lg' className='site-navigation-icons'/>
                    </Col>
                    <Col lg={1}>
                        {sifra}
                    </Col>
                    <Col lg={2}>
                        {status}
                    </Col>
                    <Col lg={1}>
                        {datum}
                    </Col>
                    <Col lg={2}>
                        {posiljatelj}
                    </Col>
                    <Col lg={2}>
                        {naslovnik}
                    </Col>
                    <Col lg={2}>
                        {oddelek}
                    </Col>
                    <Col lg={1} className='text-center'>
                        <Link to={`/vhodna/${id}`}> <FontAwesomeIcon icon='arrow-circle-right' size='lg' className='site-navigation-icons'/></Link>
                    </Col>
                </Row>
            </Card>
        </main>
    )
}

export default IncomingMailDataItem;