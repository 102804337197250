import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom'

import ProtectedRoute from '../src/components/ProtectedRoute';

//OBLIKOVANJE
import {Container, Row, Col} from 'reactstrap'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faIgloo,
    faSignInAlt,
    faSignOutAlt,
    faUsers,
    faCog,
    faUserCircle,
    faBox,
    faArrowCircleRight,
    faPlusSquare,
    faSearch,
    faEnvelope,
    faAt,
    faFax,
    faEdit,
    faUserEdit,
    faUserPlus,
    faEnvelopeOpenText,
    faTachometerAlt


} from '@fortawesome/free-solid-svg-icons'

import {getToken, logoutUser} from './adal-config'

//KOMPONENTE
import Navigation from './components/Navigation';
import Dashboard from './components/Dashboard';
import Incoming from './components/Incoming';
import Outgoing from './components/Outgoing';
import NewMail from './components/NewMail';
import Error from './components/Error';

import './main.css'

//API URL IMPORT
import {basicUrl, prijava} from './apiUrl'
import AddNewUser from "./components/AddNewUser";

//ZBIRSKA UPORABLJENIH IKON
library.add(faIgloo, faSignInAlt, faSignOutAlt, faUsers, faCog, faUserCircle, faBox, faArrowCircleRight, faPlusSquare, faSearch, faEnvelope, faAt, faFax, faEdit, faUserEdit, faUserPlus, faEnvelopeOpenText, faTachometerAlt);

class App extends Component {

    state = {
        userRole: 0,
    };

    componentDidMount() {
        const token = getToken();

        fetch(basicUrl + prijava, {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((role) => {
                this.setState({userRole: role})
            }).catch((error) => {
            console.log(error);
        });
    }

    logoutHandler = () => {
        logoutUser();
    };

    render() {
        let userAuthorizationRoutes;
        switch (this.state.userRole) {
            //IT SKRBNIK
            case 1:
                userAuthorizationRoutes = (
                    <Switch>
                        <Route path='/' exact={true} component={Dashboard}/>
                        <ProtectedRoute path='/dodaj-uporabnika' component={AddNewUser}/>
                        <Route path='/vhodna'
                               render={() => (<Incoming roleInfo={this.state.userRole}/>)}/>
                        <ProtectedRoute path='/nova' component={NewMail}/>
                        <ProtectedRoute path='/izhodna' component={Outgoing}/>
                        <Route component={Error}/>
                    </Switch>
                );
                break;
            //UREDNIK
            case 2:
                userAuthorizationRoutes = (
                    <Switch>
                        <Route path='/' exact={true} component={Dashboard}/>
                        <Route path='/vhodna'
                               render={() => (<Incoming roleInfo={this.state.userRole}/>)}/>
                        <Route path='/izhodna' component={Outgoing}/>
                        <Route path='/nova' component={NewMail}/>
                        <Route path='/dodaj-uporabnika' component={AddNewUser}/>
                        <Route component={Error}/>
                    </Switch>
                );
                break;
            //Uporabnik
            case 3:
                userAuthorizationRoutes = (
                    <Switch>
                        <Route path='/' exact={true} component={Dashboard}/>
                        <Route path='/vhodna'
                               render={() => (<Incoming roleInfo={this.state.userRole}/>)}/>
                        <Route path='/izhodna' component={Outgoing}/>
                        <ProtectedRoute path='/nova' component={NewMail}/>
                        <ProtectedRoute path='/dodaj-uporabnika' component={AddNewUser}/>
                        <Route component={Error}/>
                    </Switch>
                );
                break;
            default:
                break;
        }

        return (
            <div className="App">
                <BrowserRouter>
                    <Container fluid>
                        <Row>
                            <Col className='d-none d-md-block site-navigation' md='3' lg='3' xl='2'>
                                <Navigation
                                    userLogout={this.logoutHandler}
                                    userRole={this.state.userRole}
                                />
                            </Col>
                            <Col className='col-md-9 ml-auto' md='9' lg='9' xl='10'>
                                {userAuthorizationRoutes}
                            </Col>
                        </Row>

                    </Container>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
